import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useContext, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../utils/AuthContext';
// import { updatePassword } from '../services/UsersService';
import { toast } from 'react-toastify';
import { updatePassword } from '../services/UserService';
import { sendResetLink } from '../services/ResetPasswordService';

// TODO remove, this demo shouldn't need to reset the theme.


function ForgotPassword() {
  const { auth, setAuth } = useContext(AuthContext);



  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState('');

  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();


  function handleSubmit(event) {
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      handleSendResetLink();
    }
  }

  function validateForm() {
    let isValid = true;

    setEmailError('');


    if (email.trim() === '') {
      setEmailError('Email is required');
      isValid = false;
    }


    return isValid;
  }

  async function handleSendResetLink() {
    try {
      setLoading(true);
      const response = await sendResetLink({ email: email });

      if (response.status == 200) {
        toast.success(response.data.message, {
          autoClose: 2500,
          hideProgressBar: true,
        });

        setLoading(false);
      }

    } catch(err) {
      if (err.response.status == 400) {
        toast.error(err.response.data.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });

        setLoading(false);
      }

    }
  }



  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      {/* <MyAppBar open={open} toggleDrawer={toggleDrawer} />
      <MyDrawer open={open} toggleDrawer={toggleDrawer} /> */}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Paper sx={{ mt: 8, mx: 3, width: '400px', mx: 'auto', p:4 }}>
          <Typography variant="h4" align="center">
            Recover Account
          </Typography>

          <Box component="form" method="POST" noValidate onSubmit={handleSubmit} sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              error={emailError !== ''}
              helperText={emailError}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              size="large"
              disabled={isLoading}
            >
              Send Reset Link
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
