import ApiClient from "./ApiClient";


export const sendResetLink = ({ email }) => {
  return ApiClient.post('/api/password/email', {
    email: email
  });
}

export const resetPassword = ({ data }) => {
  return ApiClient.post('/api/password/reset', data);
}