import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useContext, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../utils/AuthContext';
// import { updatePassword } from '../services/UsersService';
import { toast } from 'react-toastify';
import { updatePassword } from '../services/UserService';
import { resetPassword } from '../services/ResetPasswordService';

// TODO remove, this demo shouldn't need to reset the theme.


function ResetPassword() {
  const { auth, setAuth } = useContext(AuthContext);

  const [searchParams] = useSearchParams();


  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [emailError, setEmailError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();


  function handleSubmit(event) {
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      handleResetPassword();
    }
  }

  function validateForm() {
    let isValid = true;

    setEmailError('');
    setNewPasswordError('');
    setConfirmPasswordError('');


    if (email.trim() === '') {
      setEmailError('Current Password is Required');
      isValid = false;
    }

    if (newPassword.trim() === '') {
      setNewPasswordError('New Password is Required');
      isValid = false;
    } else if (newPassword.trim().length < 6) {
      setNewPasswordError('New Password is too short');
      isValid = false;
    }

    if (confirmPassword.trim() === '') {
      setConfirmPasswordError('Confirm Password is Required');
      isValid = false;
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError('New Password and Confirm Password is not same');
      isValid = false;
    }

    return isValid;
  }

  async function handleResetPassword() {
    try {
      const token = searchParams.get('token');

      let data = {
        email: email,
        password: newPassword,
        password_confirmation: confirmPassword,
        token: token
      };

      setLoading(true);

      const response = await resetPassword({ data: data });

      if (response.status == 200) {
        toast.success(response.data.message, {
          autoClose: 2500,
          hideProgressBar: true,
        });

        handleRedirectToLogin();

        setLoading(false);
      }

    } catch(err) {
      toast.error(err.response.data.message, {
        autoClose: 2500,
        hideProgressBar: true,
      });

      setLoading(false);

    }
  }

  function handleRedirectToLogin() {
    navigate('/login');
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      {/* <MyAppBar open={open} toggleDrawer={toggleDrawer} />
      <MyDrawer open={open} toggleDrawer={toggleDrawer} /> */}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Paper sx={{ mt: 8, mx: 3, width: '400px', mx: 'auto', p:4 }}>
          <Typography variant="h4" align="center">
            Reset Password
          </Typography>

          <Box component="form" method="POST" noValidate onSubmit={handleSubmit} sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              error={emailError !== ''}
              helperText={emailError}
              disabled
            />
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="newPassword"
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value) }}
              error={newPasswordError !== ''}
              helperText={newPasswordError}
            />
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => { setConfirmPassword(e.target.value) }}
              error={confirmPasswordError !== ''}
              helperText={confirmPasswordError}
            />


            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              size="large"
              disabled={isLoading}
            >
              Reset Password
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default ResetPassword;
